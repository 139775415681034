// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-bowls-tsx": () => import("./../../../src/pages/bowls.tsx" /* webpackChunkName: "component---src-pages-bowls-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-eat-and-drink-drift-bar-cafe-restaurant-tsx": () => import("./../../../src/pages/eat-and-drink/drift-bar-cafe-restaurant.tsx" /* webpackChunkName: "component---src-pages-eat-and-drink-drift-bar-cafe-restaurant-tsx" */),
  "component---src-pages-eat-and-drink-index-tsx": () => import("./../../../src/pages/eat-and-drink/index.tsx" /* webpackChunkName: "component---src-pages-eat-and-drink-index-tsx" */),
  "component---src-pages-functions-tsx": () => import("./../../../src/pages/functions.tsx" /* webpackChunkName: "component---src-pages-functions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-sport-tsx": () => import("./../../../src/pages/live-sport.tsx" /* webpackChunkName: "component---src-pages-live-sport-tsx" */),
  "component---src-pages-membership-tsx": () => import("./../../../src/pages/membership.tsx" /* webpackChunkName: "component---src-pages-membership-tsx" */),
  "component---src-pages-renovations-tsx": () => import("./../../../src/pages/renovations.tsx" /* webpackChunkName: "component---src-pages-renovations-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-whats-on-events-entertainment-tsx": () => import("./../../../src/pages/whats-on/events-entertainment.tsx" /* webpackChunkName: "component---src-pages-whats-on-events-entertainment-tsx" */),
  "component---src-pages-whats-on-index-tsx": () => import("./../../../src/pages/whats-on/index.tsx" /* webpackChunkName: "component---src-pages-whats-on-index-tsx" */),
  "component---src-pages-whats-on-promotions-tsx": () => import("./../../../src/pages/whats-on/promotions.tsx" /* webpackChunkName: "component---src-pages-whats-on-promotions-tsx" */)
}

